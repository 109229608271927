<template>
    <ion-page>
        <ion-header :translucent="true">
            <ion-toolbar>
                <ion-title color="white">Dettaglio lista</ion-title>
                <ion-buttons slot="start">
                    <ion-button @click="closeModal()">
                        <ion-icon slot="start" :icon="arrowBackOutline" color="white"></ion-icon>
                    </ion-button>
                </ion-buttons>
            </ion-toolbar>
        </ion-header>

        <ion-content :fullscreen="true">
            <div class="page_content">
                <!-- Img modal, triggered with click on ticket attachment -->
                <Transition name="fade-transition">
                    <div v-show="showImgModal" class="attachment_modal">
                        <div class="customers_container">
                            <img :src="selectedImage" alt="Allegato ticket" />
                        </div>
                        <div @click="closeAttachmentModal()" class="close_modal">Chiudi</div>
                    </div>
                </Transition>

                <div v-if="loading" class="loading_spinner">
                    <ion-spinner name="crescent" color="dark"></ion-spinner>
                </div>
                <div v-else>
                    <div class="lista_title">
                        {{ lista.fi_todo_lists_name }}
                    </div>
                    <!-- <div>
                        <button type="button" @click="newTodo" class="btn_nuovo_todo">Nuovo todo</button>
                    </div> -->
                    <div class="actions_container">
                        <ion-input type="text" clear-input v-model="searchQuery" placeholder="Cerca todo" class="search_todo"></ion-input>
                        <button type="button" @click="newTodo" class="btn_nuovo_todo">
                            <ion-icon :icon="addOutline" color="white"></ion-icon>
                        </button>
                    </div>
                    <!-- Non completati -->
                    <div class="todos_container">
                        <div class="todo_title">Non completati</div>
                        <div
                            v-for="todo in searchedNotDoneTodos"
                            :key="todo.fi_todo_id"
                            class="intervento single_todo"
                            :class="doneTodo(todo)"
                            @click="toggleTodoCommpleted(todo)"
                        >
                            <div class="title">{{ todo.fi_todo_text }}</div>
                            <div class="additional_info">
                                <div class="value">
                                    {{ setShortNote(todo.fi_todo_note) }}
                                </div>
                                <div class="status">{{ todo.fi_todo_list_categories_name }}</div>
                            </div>
                        </div>
                    </div>
                    <!-- Completati -->
                    <div class="todos_container">
                        <div class="todo_title">Completati</div>
                        <div v-for="todo in searchedDoneTodos" :key="todo.fi_todo_id" class="intervento">
                            <div class="single_todo" :class="doneTodo(todo)" @click="toggleTodoCommpleted(todo)">
                                <div class="title">{{ todo.fi_todo_text }}</div>
                                <div class="additional_info">
                                    <div class="value">
                                        {{ setShortNote(todo.fi_todo_note) }}
                                    </div>
                                    <div class="status">{{ todo.fi_todo_list_categories_name }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ion-content>
    </ion-page>
</template>

<script>
import {
    IonPage,
    IonHeader,
    IonToolbar,
    IonButtons,
    //IonBackButton,
    IonTitle,
    IonSpinner,
    IonContent,
    IonIcon,
    IonInput,
    IonButton,
    modalController,
} from "@ionic/vue";
import { arrowBackOutline, addOutline } from "ionicons/icons";

import { defineComponent, onMounted, ref, computed } from "vue";

import { dateFormat } from "@/services/utils";
import { openToast } from "@/services/toast";
import apiTodo from "@/services/todo";

import ModalNuovaTodo from "@/components/todo/ModalNuovaTodo.vue";

export default defineComponent({
    name: "ListaDetail",
    props: {
        data: {
            type: Object,
            required: true,
        },
    },
    components: {
        IonPage,
        IonHeader,
        IonToolbar,
        IonButtons,
        //IonBackButton,
        IonTitle,
        IonSpinner,
        IonContent,
        IonIcon,
        IonButton,
        IonInput,
    },
    setup(props, context) {
        //console.log(props.data);
        const lista = { ...props.data };
        const loading = ref(false);
        const todos = ref([]);

        const doneTodos = ref([]);
        const notDoneTodos = ref([]);

        async function loadTodos() {
            loading.value = true;
            try {
                const res = await apiTodo.getTodos(lista.fi_todo_lists_id);
                //console.log(res);
                if (res.data.length != 0) {
                    //console.log(res.data);

                    //res.data.sort((a, b) => parseInt(a.fi_todo_order) - parseInt(b.fi_todo_order));
                    //res.data.sort((a, b) => parseInt(a.fi_todo_done) - parseInt(b.fi_todo_done));

                    res.data.data.done.sort((a, b) => parseInt(a.fi_todo_order) - parseInt(b.fi_todo_order));
                    res.data.data.notDone.sort((a, b) => parseInt(a.fi_todo_order) - parseInt(b.fi_todo_order));

                    doneTodos.value = res.data.data.done;
                    notDoneTodos.value = res.data.data.notDone;
                    console.log(doneTodos.value);
                    console.log(notDoneTodos.value);

                    //console.log(res.data);
                    todos.value = [...notDoneTodos.value, ...doneTodos.value];
                }
            } catch (error) {
                console.error(error);
                openToast("Errore durante la richiesta delle todo", "toast_danger");
            }
            loading.value = false;
        }

        /**
         * ! Funzionalità di ricerca per todo completati e non completati
         */
        const searchQuery = ref("");
        const searchedDoneTodos = computed(() => {
            const term = searchQuery.value.toLowerCase().replace(/ /g, "");

            return doneTodos.value.filter((todo) => {
                const text = todo.fi_todo_text ? todo.fi_todo_text : "";
                const note = todo.fi_todo_note ? todo.fi_todo_note : "";
                const allFields = `${text} ${note}`.toLowerCase().replace(/ /g, "");

                return allFields.includes(term);
            });
        });

        const searchedNotDoneTodos = computed(() => {
            const term = searchQuery.value.toLowerCase().replace(/ /g, "");

            return notDoneTodos.value.filter((todo) => {
                const text = todo.fi_todo_text ? todo.fi_todo_text : "";
                const note = todo.fi_todo_note ? todo.fi_todo_note : "";
                const allFields = `${text} ${note}`.toLowerCase().replace(/ /g, "");

                return allFields.includes(term);
            });
        });

        /**
         * ! Funzionalità per modificare lo stato di una todo
         * @param {*} todo
         */
        async function toggleTodoCommpleted(todo) {
            try {
                const res = await apiTodo.toggleTodo(todo.fi_todo_id, todo.fi_todo_done);

                if (res.status === 200 && res.data.status === 1) {
                    openToast("Todo modificato con successo", "toast_success");
                    todo.fi_todo_done = res.data.data.fi_todo_done;
                    //Riordino in base all'order e stato done per averli suddivisi tra completati e non completati
                    todos.value.sort((a, b) => parseInt(a.fi_todo_order) - parseInt(b.fi_todo_order));
                    todos.value.sort((a, b) => parseInt(a.fi_todo_done) - parseInt(b.fi_todo_done));
                } else {
                    openToast("Errore durante la modifica della todo", "toast_danger");
                }
            } catch (error) {
                console.error(error);
                openToast("Errore durante la modifica della todo", "toast_danger");
            }
        }

        /**
         * ! Handle close modal by clicking on Chiudi button
         */
        const closeModal = () => {
            modalController.dismiss();
        };

        /**
         * Set text underline
         */
        /* const doneTodo = computed(() => {
            return (todo) => {
                let className = "";
                if (todo.fi_todo_done == "1") {
                    //lavoro completato
                    className = "badge_low";
                }
                return className;
            };
         }); */

        function doneTodo(todo) {
            return todo.fi_todo_done && todo.fi_todo_done === "1" ? "todo_completed" : "";
        }

        /**
         * ! Limit todo note to 35 charactes
         */
        function setShortNote(note) {
            if (!note) {
                return "-";
            } else {
                //const trimmedString = note.length > 50 ? note.substring(0, 47) + "..." : note;
                const trimmedString = note;
                return trimmedString;
            }
        }

        /**
         * Remove html tag from ticket description
         */
        function parseHtmlEnteties(str) {
            if (str) {
                const strippedString = str.replace(/(<([^>]+)>)/gi, "");
                return strippedString.replace(/&#([0-9]{1,3});/gi, function(match, numStr) {
                    const num = parseInt(numStr, 10); // read num as normal number
                    return String.fromCharCode(num);
                });
            } else {
                return "-";
            }
        }

        //Set correct background for intervento status
        const statusIntervento = computed(() => {
            return (statusId) => {
                let className = "";
                if (statusId == "1") {
                    //lavoro completato
                    className = "badge_low";
                } else {
                    //lavoro non completato
                    className = "badge_high";
                }
                return className;
            };
        });

        /**
         * ! Open modal to create new todo
         */
        async function newTodo() {
            const modal = await modalController.create({
                component: ModalNuovaTodo,
                componentProps: {
                    listaId: lista.fi_todo_lists_id,
                },
            });
            modal.onDidDismiss().then((detail) => {
                if (detail.data != undefined) {
                    todos.value.unshift(detail.data);
                    openToast("Nuovo todo creata con successo", "toast_success");
                }
            });
            return modal.present();
        }

        /**
         * Open fake modal to view selected ticket attachment
         */
        const selectedImage = ref(null);
        const showImgModal = ref(false);

        function openImgDetail(image) {
            showImgModal.value = true;
            selectedImage.value = `${process.env.VUE_APP_BASE_URL}/uploads/${image.path_local}`;
        }

        function closeAttachmentModal() {
            showImgModal.value = false;
        }

        function setImageUrl(img) {
            if (img) {
                return `${process.env.VUE_APP_BASE_URL}/uploads/${img.path_local}`;
            }
        }

        onMounted(() => {
            loadTodos();
        });

        return {
            dateFormat,
            arrowBackOutline,
            closeModal,
            parseHtmlEnteties,
            setShortNote,
            statusIntervento,
            //Attachment modal
            showImgModal,
            selectedImage,
            openImgDetail,
            closeAttachmentModal,
            setImageUrl,
            //Todos
            lista,
            newTodo,
            todos,
            loading,
            doneTodo,
            toggleTodoCommpleted,
            searchQuery,
            //searchedTodos,
            // Todo separati
            searchedNotDoneTodos,
            searchedDoneTodos,
            addOutline,
        };
    },
});
</script>

<style scoped>
ion-toolbar {
    --background: #086fa3;
    --color: #ffffff;
}
.loading_spinner {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* New rules */
.page_content {
    width: 100%;
    position: relative; /* to be used for new intervento btn */
    padding: 16px;
    min-height: 100%;
    background: #f2f2f2;
}

.single_todo .title_card {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    padding: 12px 6px;
    font-weight: 600;
    color: #475569;
}
.single_todo {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding: 10px;
    background: #ffffff;
    border-radius: 4px;
    margin-bottom: 10px;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
}
.single_todo .single_todo_note {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    padding: 12px;
}
.single_todo .title,
.single_todo_note .title {
    font-size: 14px;
    font-weight: 500;
    color: #475569;
    margin-bottom: 4px;
}
.single_todo .ticket_title {
    text-transform: uppercase;
}
.single_todo_note .title {
    margin-bottom: 8px;
}
.single_todo .value,
.single_todo_note .value {
    font-size: 12px;
    white-space: nowrap; /* Impedisce il testo di andare a capo */
    overflow: hidden; /* Nasconde il testo in eccesso */
    text-overflow: ellipsis; /* Aggiunge i puntini di sospensione in caso di overflow */
    max-width: 100%; /* Assicura che il testo non superi la larghezza del contenitore */
    display: inline-block; /* Per far funzionare max-width correttamente */
}

.value .badge {
    font-size: 10px;
    font-weight: 600;
    color: #ffffff;
    padding: 4px 8px;
    border-radius: 4px;
}
.badge {
    font-size: 10px;
    color: #ffffff;
    padding: 4px 8px;
    border-radius: 4px;
}
.badge_close,
.badge_low {
    background-color: rgb(220 252 231);
    color: rgb(34 197 94);
    font-weight: bold;
}
.badge_standy,
.badge_high {
    background-color: rgb(254 226 226);
    color: rgb(239 68 68);
    font-weight: bold;
}
.badge_working,
.badge_medium {
    background-color: rgb(255 237 213);
    color: rgb(249 115 22);
    font-weight: bold;
}
.badge_waiting_answer {
    background-color: rgb(237 233 254);
    color: rgb(139 92 246);
    font-weight: bold;
}
.badge_open {
    background-color: rgb(207 250 254);
    color: rgb(6 182 212);
    font-weight: bold;
}
.badge_canceled {
    background-color: rgb(243 244 246);
    color: rgb(31 41 55);
    font-weight: bold;
}

.nuovo_intervento {
    width: 100%;
    margin-top: 16px;
    padding-bottom: 0;
    display: flex;
    justify-content: center;
}
.intervento_button {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    background-color: #086fa3;
    color: #ffffff;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
    transition: all 0.15s ease-in;
}

/* Elenco interventi */
.interventi_container {
    margin-top: 16px;
}
.interventi_container .interventi_label {
    margin-bottom: 12px;
    font-size: 18px;
    font-weight: bold;
    color: #2a2a2a;
}
.single_todo .date {
    font-size: 14px;
    font-weight: 500;
    color: #475569;
}
.single_todo .status {
    font-size: 14px;
}

ion-button {
    --color: #ffffff;
}
/** Allegati  */
.allegati_container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow-x: scroll;
}
.allegati_container img {
    width: 200px;
    margin-right: 16px;
}
.single_todo .single_todo_allegati {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    padding: 12px;
}
.single_todo_allegati .title {
    font-size: 14px;
    font-weight: 500;
    color: #475569;
}
.single_todo_allegati .title {
    margin-bottom: 8px;
}
.single_todo_allegati .value {
    font-size: 14px;
}

/** Modal selected attachment */
.fade-transition-enter-active,
.fade-transition-leave-active {
    transition: opacity 0.25s ease;
}

.fade-transition-enter-from,
.fade-transition-leave-to {
    opacity: 0;
}
.attachment_modal {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.75);
    color: #ffffff;
    padding: 24px;
    align-items: center;
}
.close_modal {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    color: #ffffff;
    background-color: #086fa3;
    font-size: 16px;
    font-weight: 500;
    border-radius: 20px;
    margin-top: 16px;
}

.title.rapportino_container {
    display: flex;
    justify-content: center;
    margin-top: 8px;
    font-size: 16px;
}
a.link_rapportino {
    color: #086fa3;
    text-decoration: none;
}

.todo_completed {
    text-decoration: line-through;
}

.single_todo .additional_info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 8px;
}

.actions_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    margin-bottom: 24px;
}

.single_todo .status {
    align-self: end;
    font-size: 10px;
    font-weight: bold;
    padding: 4px 8px;
    border-radius: 4px;
    background-color: rgb(219 234 254);
    color: rgb(59 130 246);
    text-decoration: none;
}

.btn_nuovo_todo {
    padding: 10px;
    font-size: 20px;
    font-weight: 600;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    background-color: #0a90d4;
    color: #ffffff;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
}
/**
* Ricerca todo
*/
.search_todo {
    width: 100%;
    border: 1px solid #c3c8cf;
    border-radius: 4px;
    padding: 8px;
    --background: #ffffff;
}
.search_todo::placeholder {
    color: #6b7280;
}
.search_todo:focus-visible {
    outline: 1px solid #086fa3;
}

.lista_title {
    font-size: 18px;
    font-weight: 600;
    color: #475569;
    margin-bottom: 12px;
}

.todos_container {
    margin-bottom: 32px;
}
.todos_container .todo_title {
    font-size: 16px;
    font-weight: 600;
    color: #475569;
    margin-bottom: 12px;
}
</style>

<template>
    <ion-page>
        <ion-header :translucent="true">
            <ion-toolbar>
                <ion-title color="white">Liste todo</ion-title>
                <ion-buttons @click="openMenu()" slot="start">
                    <ion-button>
                        <ion-icon :icon="menu" slot="start"></ion-icon>
                    </ion-button>
                </ion-buttons>
                <ion-buttons slot="end">
                    <ion-button @click="loadListe()">
                        <ion-icon slot="start" :icon="refresh" color="white"></ion-icon>
                    </ion-button>
                </ion-buttons>
            </ion-toolbar>
        </ion-header>

        <ion-content :fullscreen="true">
            <div v-if="loading" class="loading_spinner">
                <ion-spinner name="crescent" color="dark"></ion-spinner>
            </div>
            <div class="page_content" v-else>
                <!-- <div class="nuovo_intervento">
                    <div class="intervento_button" @click="openNuovoIntervento">Nuova lista</div>
                </div> -->

                <div v-if="!lists.length" class="no_interventi_container">
                    <div class="no_interventi">Nessun lista da visualizzare</div>
                </div>

                <div v-else class="container_interventi">
                    <div>
                        <ion-input type="text" clear-input v-model="searchQueryListe" placeholder="Cerca lista" class="search_list"></ion-input>
                    </div>
                    <div class="intervento" v-for="lista in searchedListe" :key="lista.lists" @click="openListaDetail(lista)">
                        <div class="intervento_info">
                            <div class="date">{{ lista.fi_todo_lists_name }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </ion-content>
    </ion-page>
</template>

<script>
import {
    IonPage,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonSpinner,
    IonIcon,
    IonButton,
    IonInput,
    modalController,
    IonButtons,
    menuController,
} from "@ionic/vue";
import { add, refresh, menu } from "ionicons/icons";

import { ref, computed, onMounted } from "vue";
import { useRouter } from "vue-router";

import { LOCALSTORAGE_KEY } from "@/custom_config";

import { dateFormat } from "@/services/utils";
import { openToast } from "@/services/toast";
import apiTodo from "@/services/todo";

import ListaDetail from "@/components/todo/ListaDetail.vue";
import ModalNuovoIntervento from "@/components/interventi/ModalNuovoIntervento.vue";

export default {
    name: "ToDoList",
    components: {
        IonHeader,
        IonToolbar,
        IonTitle,
        IonContent,
        IonPage,
        IonSpinner,
        IonIcon,
        IonButton,
        IonInput,
        IonButtons,
    },
    setup() {
        const router = useRouter();
        const loading = ref(false);
        const richieste = ref([]);
        const userID = JSON.parse(localStorage.getItem(`${LOCALSTORAGE_KEY}`)).dipendenti_user_id;
        const dipendenteID = JSON.parse(localStorage.getItem(`${LOCALSTORAGE_KEY}`)).dipendenti_id;

        const openMenu = () => {
            menuController.open("app-menu");
        };

        const lists = ref([]);
        async function loadListe() {
            loading.value = true;
            try {
                const res = await apiTodo.getLists(userID);
                if (res.data.length != 0) {
                    lists.value = res.data;
                }
            } catch (error) {
                console.error(error);
                openToast("Errore durante la richiesta delle liste", "toast_danger");
            }
            loading.value = false;
        }

        const searchQueryListe = ref("");
        const searchedListe = computed(() => {
            const term = searchQueryListe.value.toLowerCase().replace(/ /g, "");

            return lists.value.filter((list) => {
                const titleList = list.fi_todo_lists_name ? list.fi_todo_lists_name : "";
                const allFields = `${titleList}`.toLowerCase().replace(/ /g, "");

                return allFields.includes(term);
            });
        });

        /**
         * ! Set correct background for intervento status
         */
        const statusIntervento = computed(() => {
            return (statusId) => {
                let className = "";
                if (statusId == 1) {
                    //ticket aperti
                    className = "badge_low";
                } else if (statusId == 2) {
                    //ticket in lavorazione
                    className = "badge_high";
                }
                return className;
            };
        });

        /**
         * ! Open modal to create new intervento
         */
        /*async function openNuovoIntervento() {
            const modal = await modalController.create({
                component: ModalNuovoIntervento,
            });
            modal.onDidDismiss().then((detail) => {
                if (detail.data != undefined) {
                    interventi.value.unshift(detail.data);
                    openToast("Intervento salvato con successo", "toast_success");
                }
            });
            return modal.present();
        }*/

        /**
         * ! Open intervento detail
         */
        async function openListaDetail(selectedLista) {
            const modal = await modalController.create({
                component: ListaDetail,
                componentProps: {
                    data: selectedLista,
                },
            });
            return modal.present();
        }

        onMounted(() => {
            loadListe();
        });

        return {
            loading,
            add,
            refresh,
            //openNuovoIntervento,
            //getRichieste,
            openListaDetail,
            openMenu,
            menu,
            loadListe,
            //Liste
            lists,
            searchQueryListe,
            searchedListe,
        };
    },
};
</script>

<style scoped>
ion-toolbar {
    --background: #086fa3;
    --color: #ffffff;
}
.loading_spinner {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* New rules */
.no_interventi_container {
    width: 100%;
    padding: 16px;
    padding-top: 32px;
}
.no_interventi {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px;
    background-color: rgb(226 232 240);
    color: rgb(148 163 184);
    font-size: 16px;
    font-weight: 500;
    border-radius: 4px;
}

.page_content {
    width: 100%;
    position: relative; /* to be used for new richiesta btn */
    min-height: 100%;
    background: #f2f2f2;
}
.container_interventi {
    width: 100%;
    padding: 16px;
    padding-bottom: 64px; /* to avoid overlap with latest richiesta */
}
.intervento {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background: #ffffff;
    border-radius: 4px;
    margin-bottom: 10px;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
}

.intervento .date {
    font-size: 14px;
    font-weight: 500;
    color: #475569;
    margin-bottom: 4px;
}
.intervento .customer {
    font-size: 12px;
    color: #6b7280;
}

.intervento .badge {
    font-size: 10px;
    font-weight: 600;
    color: #ffffff;
    padding: 4px 8px;
    border-radius: 4px;
}
.intervento .badge_success {
    background-color: rgb(220 252 231);
    color: rgb(34 197 94);
}
.intervento .badge_warning {
    background-color: rgb(254 243 199);
    color: rgb(245 158 11);
}
.intervento .badge_danger {
    background-color: rgb(254 226 226);
    color: rgb(239 68 68);
}

/* Nuova richiesta */
.nuovo_intervento {
    width: 100%;
    padding: 16px;
    padding-bottom: 0;
    display: flex;
    justify-content: center;
}
.intervento_button {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 20px;
    text-align: center;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
    background-color: #086fa3;
    color: #ffffff;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
}
ion-button {
    --color: #ffffff;
}
.search_list {
    width: 100%;
    border: 1px solid #c3c8cf;
    border-radius: 4px;
    padding: 8px;
    margin-bottom: 16px;
    --background: #ffffff;
}
.search_list::placeholder {
    color: #6b7280;
}
.search_list:focus-visible {
    outline: 1px solid #086fa3;
}
</style>
